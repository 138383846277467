const axios = require("axios");
import $ from "jquery";
import utils from "./utils";

var doAfter = true;


export default {
    toggleCartAdditionalItems() {
        document.addEventListener("click", function (e) {
            if (e.target.getAttribute("data-additional-toggle")) {
                let hide = false;
                if (e.target.classList.contains("active")) {
                    hide = true;
                } else {
                    e.target.classList.add("active");
                }

                let allItems = document.querySelectorAll("[data-additional]");
                allItems.forEach(function (item) {
                    item.classList.remove("active");
                });

                let key = e.target.getAttribute("data-additional-toggle");
                let items = document.querySelectorAll(`[data-additional="${key}"]`);
                items.forEach(function (e, i) {
                    e.classList.add("active");
                });

                if (hide == true) {
                    items.forEach(function (e, i) {
                        e.classList.remove("active");
                    });
                    e.target.classList.remove("active");
                }
            }
        });
    },
    goToShippingFakeButton() {
        let onboardingRadio = document.querySelectorAll(
            '.cart-onboarding input[type=radio]'
        );
        let onboardingRadioChecked = document.querySelector(
            '.cart-onboarding input[type=radio]:checked'
        );
        let btn = document.querySelector(".woocommerce-cart__go-to-shipping");
        if (!btn) {
            return;
        }
        btn.addEventListener("click", function (e) {
            e.preventDefault();
            onboardingRadio = document.querySelectorAll(
                '.cart-onboarding input[type=radio]'
            );
            onboardingRadioChecked = document.querySelector(
                '.cart-onboarding input[type=radio]:checked'
            );
            onboardingRadio.forEach(radio => {
                radio.addEventListener('click', () => {
                    $('.woocommerce-error.woocommerce-error--onboarding').remove();
                })
            })

            if (!onboardingRadioChecked) {
                let $onboardingErrorNotice = $('.woocommerce-error.woocommerce-error--onboarding');

                if ($onboardingErrorNotice.length === 0) {
                    $('.article-body').append(
                        $('<div class="woocommerce-error woocommerce-error--onboarding"><li>Please select an Onboarding option.</li></div>')
                    );
                }
                if (
                    document.querySelector('.article-body').getBoundingClientRect().bottom + document.documentElement.scrollTop >
                    document.documentElement.scrollTop + window.innerHeight
                ) {
                    // console.log(document.documentElement.scrollTop);
                    console.log(document.querySelector('.article-body').getBoundingClientRect().bottom + document.documentElement.scrollTop);
                    window.scrollTo(0, document.querySelector('.article-body').getBoundingClientRect().bottom + document.documentElement.scrollTop - window.innerHeight + 50);
                }
            }

            if (!onboardingRadioChecked) {
                return;
            }
            document.querySelector(".checkout-button").click();
        });
    },

    handleFakeCouponInput() {
        document.addEventListener("click", function (e) {
            if (e.target.classList.contains("fake-coupon-submit")) {
                e.preventDefault();
                document.querySelector('[name="apply_coupon"]').click();
            }
        });
        document.addEventListener("keyup", function (e) {
            if (e.target.classList.contains("fake-coupon-input")) {
                let val = e.target.value;
                let actualInput = document.querySelector("#coupon_code");
                actualInput.value = val;
            }
        });
    },

    handleWCLabels() {
        let inputs = document.querySelectorAll("#customer_details .form-row");
        inputs.forEach((element) => {
            let elInputs = element.querySelectorAll("input, select");

            elInputs.forEach((elInput) => {
                if (elInput) {
                    elInput.addEventListener("focus", function () {
                        element.classList.add("has-val");
                    });
                    elInput.addEventListener("focusout", function () {
                        if (!elInput.value) {
                            element.classList.remove("has-val");
                        }
                    });
                    if (elInput.value) {
                        element.classList.add("has-val");
                    }
                    document.addEventListener("keyup", function (e) {
                        let parent = e.target.closest(".form-row");
                        if (!parent) {
                            return;
                        }
                        if (e.target.value) {
                            parent.classList.add("has-val");
                            parent.classList.add("has-val-perm");
                        } else {
                            parent.classList.remove("has-val");
                            parent.classList.remove("has-val-perm");
                        }
                    });
                }
            });
        });
    },

    showHideVatField() {
        let inputs = document.querySelectorAll(`[name="company_or_private"]`);
        let vatInput = document.querySelectorAll(
            "#vat_no_field, .vat-info, .vat-info__output"
        );
        inputs.forEach((input) => {
            input.addEventListener("change", function () {

                if (input.value == "Company") {
                    vatInput.forEach((element) => {
                        element.classList.add("active");
                    });
                } else {
                    // if user changed back from Company to Private, recalculate totals
                    // @todo aktualnie teraz na kazdym kliku jest update checkout, idealnie by bylo odpalac tylko jesli to pole
                    // @todo sie rzeczywiscie zmienilo - trzeba by dorobic jakas flage do sprawdzania czy byla jakas wartosc
                    document.querySelector('#vat_no').value = null;
                    jQuery("body").trigger("update_checkout");
                    vatInput.forEach((element) => {
                        element.classList.remove("active");
                    });
                }
            });
        });
    },

    shiftCartLayout(amount = false) {
        let elToShift = document.querySelector(".summary-coupon-wrap");
        if (!elToShift) {
            return;
        }
        let height = document
            .querySelector(".background-wrap")
            .getBoundingClientRect().height;

        let shiftAmount = parseInt(height) + 50;

        if (amount == false) {
            elToShift.style.transform = `translateY(-${shiftAmount}px)`;
            elToShift.style.top = shiftAmount + 50 + "px";
            scroll(0, 0);
        } else {
            elToShift.style.transform = `translateY(-${0}px)`;
            elToShift.style.top = 50 + "px";
        }
    },

    handleGoToSummaryButton() {
        let woocommerceSummary = document.querySelector(
            ".woocommerce-checkout .woocommerce"
        );
        let dis = this;
        let orderAsRadio = document.querySelectorAll(
            '#customer_details input[type=radio]'
        );
        let orderAsRadioChecked = document.querySelector(
            '#customer_details input[type=radio]:checked'
        );
        let onboardingRadio = document.querySelectorAll(
            '.cart-onboarding input[type=radio]'
        );
        let onboardingRadioChecked = document.querySelector(
            '.cart-onboarding input[type=radio]:checked'
        );

        document.addEventListener("click", function (e) {
            if (e.target.classList.contains("custom-summary-view__edit")) {
                jQuery("body").trigger("update_checkout");
                jQuery('.cart-in-checkout').unblock();
            }
        })
        document.addEventListener("click", function (e) {

            if (e.target.classList.contains("-js-button-go-to-summary")) {
                let $selectedPayment = $('.woocommerce-checkout-payment .wc_payment_method--active');

                orderAsRadioChecked = document.querySelector(
                    '#customer_details input[type=radio]:checked'
                );
                onboardingRadio = document.querySelectorAll(
                    '.cart-onboarding input[type=radio]'
                );
                onboardingRadioChecked = document.querySelector(
                    '.cart-onboarding input[type=radio]:checked'
                );
                onboardingRadio.forEach(radio => {
                    radio.addEventListener('click', () => {
                        $('.woocommerce-error.woocommerce-error--onboarding').remove();
                    })
                })

                let paymentMethods = document.querySelectorAll('.woocommerce-checkout-payment .wc_payment_method');
                paymentMethods.forEach(method => {
                    method.addEventListener('click', () => {
                        $('.woocommerce-error.woocommerce-error--payment').remove();
                    })
                })

                if (!dis.isNewKitInTheCart() && !onboardingRadioChecked) {
                    let $onboardingErrorNotice = $('.woocommerce-error.woocommerce-error--onboarding');

                    if ($onboardingErrorNotice.length === 0) {
                        $('.only-ups .wc_error_messages').append(
                            $('<div class="woocommerce-error woocommerce-error--onboarding"><li>Please select an Onboarding option.</li></div>')
                        );
                    }
                }

                if (!orderAsRadioChecked) {
                    let $orderAsErrorNotice = $('.woocommerce-error.woocommerce-error--order-as');

                    if ($orderAsErrorNotice.length === 0) {
                        $('.only-ups .wc_error_messages').append(
                            $('<div class="woocommerce-error woocommerce-error--order-as"><li>Please check whether you order as Company or Private.</li></div>')
                        );
                    }
                }

                e.preventDefault();

                if ($selectedPayment.length === 0) {
                    let $paymentErrorNotice = $('.woocommerce-error.woocommerce-error--payment');

                    if ($paymentErrorNotice.length === 0) {
                        $('.only-ups .wc_error_messages').append(
                            $('<div class="woocommerce-error woocommerce-error--payment"><li>Please select payment method.</li></div>')
                        );
                    }
                }

                if ($selectedPayment.length === 0 || !orderAsRadioChecked || (!dis.isNewKitInTheCart() && !onboardingRadioChecked)) {
                    return;
                }

                woocommerceSummary.classList.add("woocommerce-is-summary-view");
                dis.shiftCartLayout();

                dis.checkoutBreadcrumbsGoToStep(2);

                // temp. commented out - problem with payment due to refreshing cart, card details (number, cvv) is cleared after clicking 'go to summary' button
                // jQuery("body").trigger("update_checkout");
                // @TODO CHECK 
            }

            let isBillingSameAsShipping = document.querySelector(
                "#ship-to-different-address-checkbox"
            );
            let allOthers = document.querySelectorAll(
                ".custom-summary-view__not-same_as_billing"
            );
            let sameAsBilling = document.querySelector(
                ".custom-summary-view__same_as_billing"
            );
            if (isBillingSameAsShipping) {
                if (!isBillingSameAsShipping.checked) {
                    sameAsBilling.classList.add("active");
                    allOthers.forEach((element) => {
                        element.classList.remove("active");
                    });
                } else {
                    allOthers.forEach((element) => {
                        element.classList.add("active");
                    });
                    sameAsBilling.classList.remove("active");
                }
            }

            dis.mapInputs(false);
        });

        if (!orderAsRadioChecked) {
            orderAsRadio.forEach(radio => {
                radio.addEventListener('click', () => {
                    $('.woocommerce-error.woocommerce-error--order-as').remove();
                })
            })
        }
    },
    handleEditButton() {
        let woocommerceSummary = document.querySelector(
            ".woocommerce-checkout .woocommerce"
        );
        let dis = this;
        document.addEventListener("click", function (e) {
            if (e.target.classList.contains("custom-summary-view__edit")) {
                e.preventDefault();

                setTimeout(() => {
                    let target = e.target.getAttribute("data-go");
                    let offset = document.querySelector(target);
                    scroll(0, offset.getBoundingClientRect().y - 50);
                }, 500);

                woocommerceSummary.classList.remove("woocommerce-is-summary-view");
                dis.checkoutBreadcrumbsGoToStep(0);
                dis.shiftCartLayout(true);
            }
        });
    },

    handleOrderButton() {
        let dis = this;
        document.addEventListener("click", function (e) {
            dis.getChosenPayment();

            if (e.target.classList.contains("-js-button-place-order")) {
                e.preventDefault();
                document.querySelector("#place_order").click();
            }
        });
    },

    mapInputs(addListener = true) {
        this.mapInput('[name="billing_first_name"]', addListener);
        this.mapInput('[name="billing_last_name"]', addListener);
        this.mapInput('[name="billing_address_1"]', addListener);
        this.mapInput('[name="billing_postcode"]', addListener);
        this.mapInput('[name="billing_city"]', addListener);
        this.mapInput('[name="billing_country"]', addListener);
        this.mapInput('[name="billing_email"]', addListener);
        this.mapInput('[name="billing_phone"]', addListener);
        this.mapInput('[name="billing_company"]', addListener);
        this.mapInput('[name="vat_number"]', addListener);

        this.mapInput('[name="shipping_first_name"]', addListener);
        this.mapInput('[name="shipping_last_name"]', addListener);
        this.mapInput('[name="shipping_company"]', addListener);
        this.mapInput('[name="shipping_address_1"]', addListener);
        this.mapInput('[name="shipping_postcode"]', addListener);
        this.mapInput('[name="shipping_city"]', addListener);
        this.mapInput('[name="shipping_state"]', addListener);
        this.mapInput('[name="shipping_country"]', addListener);
        this.mapInput('[name="shipping_country"]', addListener);

        let textArea = document.querySelector('[name="additional_comments"]');
        if (textArea) {
            textArea.addEventListener("keyup", function () {
                let taApply = document.querySelector('[name="order_comments"]');
                taApply.value = textArea.value;
            });
        }
    },

    mapInput(field, addListener) {
        let orgInput = document.querySelector(field);
        if (!orgInput) {
            return;
        }

        if (orgInput.value) {
            let name = orgInput.getAttribute("name");
            if (name) {
                let cloneField = document.querySelector(`[data-map="${name}"]`);
                if (cloneField && orgInput.value) {
                    cloneField.innerHTML = orgInput.value;
                }
            }

            if (addListener == true) {
                document.addEventListener("keyup", function (e) {
                    if (e.target.getAttribute("name") == orgInput.getAttribute("name")) {
                        let name = orgInput.getAttribute("name");
                        let cloneField = document.querySelector(`[data-map="${name}"]`);
                        cloneField.innerHTML = e.target.value;
                    }
                });
            }
        }
    },

    getChosenPayment() {
        let vals = document.querySelectorAll('[name="payment_method"]');

        vals.forEach((element) => {
            if (element.checked) {
                let label = element
                    .closest(".wc_payment_method")
                    .querySelector("label").innerHTML;
                let paymentMethodInput = document.querySelector(".payment_method-input");
                if (paymentMethodInput) {
                    paymentMethodInput.innerHTML = label;
                }
                this.choose_paymeny = element.value;
                console.log(this.choose_paymeny)
                this.whichPaymentSelected();

                // remove error notice message if exists
                $('.woocommerce-error.woocommerce-error--payment').remove();
            }
        });
    },

    whichPaymentSelected() {
        let vals = document.querySelectorAll('[name="payment_method"]');
        vals.forEach((element) => {
            element.checked = false;
            if (element.value == this.choose_paymeny) {
                element.checked = true;
            }

        });
    },

    handleCartItemParameterChange() {
        let dis = this;

        let selects = document.querySelectorAll(".product-plug-type--desktop");
        let firstUpdate = false;
        let cart_ajax_object = [];

        for (let i = 0; i < selects.length; i++) {
            const element = selects[i];

            let search    = element.getAttribute('data-looup-item');
            let cart_item = document.querySelector(`.cart_item[data-item="${search}"]`);
            let value     = element.value;
            let item      = cart_item.getAttribute("data-item");
            let qty       = (cart_item.querySelector("input.qty")) ? cart_item.querySelector("input.qty").value : 1;
            cart_ajax_object.push({
                "item": item,
                "qty": qty,
                "value": value,
            });
        }

        let form_data = new FormData();
        form_data.append("action", "cart_item_parameter_change_on_load");
        form_data.append("cart_object", JSON.stringify(cart_ajax_object));

        axios.post(ajaxUrl, form_data).then(function (response) {

        });

        const debounce = (func, timeout = 500) => {
            let timer;
            return (...args) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(this, args);
                }, timeout);
            };
        }
        var doRef = false

        const handleParameterChange = (e) => {
            jQuery('.cart-in-checkout').block({
                message: null,
                overlayCSS: {
                    cursor: 'none',
                    background: '#fff',
                    opacity: 0.6
                }
            });
            this.clearNotices();

            if (document.querySelector('td[data-additional-toggle]')) {
                isToggle = document.querySelector('td[data-additional-toggle]').classList.contains('active');
            }
            let qtyInputs = document.querySelectorAll('input[type=number]');

            if (
                e.target.classList.contains("product-plug-type") ||
                (e.target.classList.contains("qty") && e.target.value)
            ) {
                let form_data = new FormData();

                let searchFor = e.target.getAttribute("data-looup-item");
                if (!searchFor) {
                    searchFor = e.target
                        .closest(".product-quantity")
                        .getAttribute("data-looup-item");
                }

                let cart_item = document.querySelector(
                    `.cart_item[data-item="${searchFor}"]`
                );

                let qty   = (cart_item.querySelector("input.qty")) ? cart_item.querySelector("input.qty").value : 1;
                const maxLimit = cart_item.querySelector("input.qty").getAttribute("max");
                console.log(cart_item);

                if (parseInt(qty) > parseInt(maxLimit)) {
                    const productName = cart_item.querySelector('.product-name').getAttribute('data-product-name');
                    qty = maxLimit;
                    cart_item.querySelector("input.qty").value = maxLimit;

                    if (productName) {
                        this.displayWooCommerceNotice(`Maximum quantity allowed for <strong>${productName}</strong> in cart is ${maxLimit}.`);
                    }
                }

                let custom_attr;
                if (e.target.classList.contains("product-plug-type--mobile")) {
                    let additional_item = document.querySelector(
                        `.additional-select[data-additional="${searchFor}"]`
                    );
                    custom_attr = additional_item.querySelector(
                        "select.product-plug-type--mobile"
                    );
                } else {
                    custom_attr = cart_item.querySelector("select.product-plug-type");
                }

                if (custom_attr) {
                    let value = custom_attr.value;
                    form_data.append("value", value);
                }
                let item = cart_item.getAttribute("data-item");

                form_data.append("action", "cart_item_parameter_change");
                form_data.append("item", item);
                form_data.append("qty", qty);


                axios.post(ajaxUrl, form_data).then(function (response) {
                    refresher(qty)
                });
            }
        }

        function refresher() {
            let form_data = new FormData();
            var changed = false;

            var qty = 0;

            jQuery('.product-quantity[data-title="Quantity"]').each(function () {
                if($(this).find('.qty').length > 0) {
                    qty = qty + parseInt($(this).find('.qty').val())
                } else {
                    // if product do not have quantity input
                    qty = qty + 1
                }
            })
            doAfter = true;

            form_data.append("action", "ret_cart_qty");

            axios.post(ajaxUrl, form_data).then(function (response) {
                doRef = true

                if (response.data == qty) {
                    dis.refreshCartInCheckout();
                    dis.doAfterWC();

                    jQuery('.cart-in-checkout').unblock();

                    if (document.body.classList.contains("woocommerce-checkout")) {
                        jQuery("body").trigger("update_checkout");
                    } else if (document.body.classList.contains("woocommerce-cart")) {
                        document.querySelector('[name="update_cart"]').click();
                    }
                    changed = true;
                    doRef = false
                }
            });
        }


        let isToggle = false;

        const processChange = debounce((e) => handleParameterChange(e), 500);
        let formContainer = document.querySelector('.woocommerce-cart-form-container');

        if (formContainer) {
            formContainer.addEventListener("change", (e) => processChange(e));
        }

        document.addEventListener('change', function (e) {
            if(e.target.classList.contains('qty')) {
                processChange(e);
            }
        })

        // do weryfikacji dla łukasza

        let onboardingRadioButtons = document.querySelectorAll('.onboarding-options input[type=radio]');
        let radioOption;

        const radioListener = (e) => {
            radioOption = e.target;
        }


        onboardingRadioButtons.forEach(radio => {
            radio.addEventListener('click', radioListener);
        })

        let formContainerObserver = new MutationObserver((e) => {
            if (e[0].addedNodes) {
                formContainer = document.querySelector('.woocommerce-cart-form-container');
                if (formContainer) {
                    formContainer.addEventListener("change", (e) => processChange(e));
                }
                onboardingRadioButtons = document.querySelectorAll('.onboarding-options input[type=radio]');
                onboardingRadioButtons.forEach(radio => {
                    radio.addEventListener('click', radioListener);
                });
                if (radioOption) {
                    document.querySelector(`#${radioOption.id}`).checked = true;
                }
                if (isToggle && e[0].addedNodes.length > 1) {
                    document.querySelector('td[data-additional-toggle]').classList.add('active');
                    document.querySelectorAll('.additional-items').forEach(item => {
                        item.classList.add('active');
                    });
                }
            }
        });

        formContainerObserver.observe(document.querySelector('.article-body'), {childList: true, subtree: true});
        // do weryfikacji dla łukasza

    },

    refreshCartInCheckout() {
        var after = this;

        jQuery('.cart-in-checkout').block({
            message: null,
            overlayCSS: {
                cursor: 'none',
                background: '#fff',
                opacity: 0.6
            }
        });

        axios.get("/checkout/").then(function (response) {

            jQuery('.woocommerce-message, .wc_error_messages, .woocommerce-error').html('');

            var parser = new DOMParser();
            var htmlDoc = parser.parseFromString(response.data, "text/html");
            let newHtml = htmlDoc.querySelector(".cart-in-checkout").innerHTML;

            let cartInCheckout = document.querySelector(".cart-in-checkout");
            if (cartInCheckout) {
                cartInCheckout.innerHTML = newHtml;
            }

            jQuery('.cart-in-checkout').unblock({});
        });
    },

    /**
     * On checkout page, disable options in country selects. Countries to be block controlled by Theme options.
     */
    blockShippingCountries() {
        const excludedCountriesArray = app_js_localized.shipping_excluded;
        const $billingCountrySelect = $("#billing_country");
        const $shippingCountrySelect = $("#shipping_country");
        let $billingOption, $shippingOption;

        if (!excludedCountriesArray) {
            return;
        }
        excludedCountriesArray.forEach(function (item) {
            $billingOption = $billingCountrySelect.find(`option[value="${item}"]`);
            $shippingOption = $shippingCountrySelect.find(`option[value="${item}"]`);

            $billingOption
                .prop("disabled", true)
                .text(`${$billingOption.text()} (Contact sales)`);

            $shippingOption
                .prop("disabled", true)
                .text(`${$shippingOption.text()} (Contact sales)`);
        });
    },

    handleVatNumberChange() {
        document.addEventListener(
            "keyup",
            utils.debounce(function (e) {
                if (e.target.getAttribute("id") === "vat_no") {
                    jQuery("body").trigger("update_checkout");
                }
            }, 500)
        );

        jQuery('#vat_no').on('blur', function() {
            jQuery("body").trigger("update_checkout");
        });
    },

    handlePostcodeChange() {
        document.addEventListener(
            "keyup",
            utils.debounce(function (e) {
                if (e.target.getAttribute("id") === "shipping_postcode"
                    || e.target.getAttribute("id") === 'billing_postcode') {
                    jQuery("body").trigger("update_checkout");
                }
            }, 500)
        );

        jQuery('#shipping_postcode, #billing_postcode').on('blur', function() {
            jQuery("body").trigger("update_checkout");
        });
    },

    displayMessageIfQtyMoreThan() {
        const cartItemsQty = document.querySelectorAll("input.qty");
        const cartActions = document.querySelector(".woocommerce-cart__actions");
        const checkoutActions = document.querySelector(".final_actions");
        const max = 50;

        if (cartActions) {
            cartActions.classList.remove("display-contact-sales");
        }
        if (checkoutActions) {
            checkoutActions.classList.remove("display-contact-sales");
        }

        cartItemsQty.forEach(function (e, i) {
            if (e.value) {
                let qty = e.value;
                if (qty >= max) {
                    if (cartActions) {
                        cartActions.classList.add("display-contact-sales");
                    }
                    if (checkoutActions) {
                        checkoutActions.classList.add("display-contact-sales");
                    }
                }
            }
        });
    },

    validateVatNumber() {
        let blockUi = `<div class="blockUI blockOverlay" style="z-index: 1000; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background: rgb(255, 255, 255); opacity: 0.6; cursor: default; position: absolute;"></div>`;
        let customerDetails = document.querySelector("#customer_details");
        document.addEventListener(
            "click",
            utils.debounce(function (e) {
                if (e.target.classList.contains("vat-info__link")) {
                    let vat_no = document.querySelector("input#vat_no").value;
                    let country = document.querySelector(
                        '[name="billing_country"]'
                    ).value;

                    let form_data = new FormData();
                    form_data.append("action", "validate_vat_number");
                    form_data.append("vat_number", vat_no);
                    form_data.append("country", country);
                    let outputContainer = document.querySelector(".vat-info__output");
                    outputContainer.innerHTML = "";

                    customerDetails.insertAdjacentHTML("afterbegin", blockUi);

                    axios.post(ajaxUrl, form_data).then(function (response) {
                        if (response.data) {
                            customerDetails.querySelector(".blockUI").remove();
                            outputContainer.insertAdjacentHTML("afterbegin", response.data);
                        }
                    });
                }
            }, 500)
        );
    },

    checkoutCountrySelects() {
        jQuery('body').on('change', '#billing_country', function () {
            //for some reason, checked state shows opposite result - using negation to correct it
            let selectedBillingCountry = $(this).val();
            let isSameShippingAsBillingCheckboxChecked = !jQuery('#ship-to-different-address-checkbox').is(':checked');
            if (isSameShippingAsBillingCheckboxChecked) {
                jQuery('#shipping_country').val(selectedBillingCountry).trigger('change');
                // TODO
            }
        });
    },

    onboardingWithOptions() {
        let dis = this;
        let selectedOption;

        jQuery('body').on('change', '.onboarding-options input[type="radio"]', function () {
            selectedOption = $(this).val();

            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    'action': 'save_onboarding_for_cart_ajax',
                    'onboarding-option': selectedOption
                },
                cache: false,
            }).done(function (response) {
                dis.refreshCartInCheckout();
                jQuery("body").trigger("update_checkout");
            });
        });
    },

    doAfterWC() {

        jQuery('.fake-coupon-form:eq(1), .only-ups:eq(1)').remove();
        var errors = jQuery('.woocommerce-error').html();
        var messages = jQuery('.woocommerce-message').html();

        jQuery('.wc_error_messages').html('');
        if (errors) {
            console.log(errors);
            jQuery('.wc_error_messages').append(`<div class="woocommerce-error">${errors}</div>`);
        } else if (messages) {
            jQuery('.wc_error_messages').append(`<div class="woocommerce-message">${messages}</div>`);

        } else {
            jQuery('.wc_error_messages').find('.woocommerce-error').remove();
        }
        if(document.querySelector('.payment_method_bacs')) {
            document.querySelector('.payment_method_bacs > label').innerHTML = 'Bank Transfer';
        }
    },

    checkoutBreadcrumbsGoToStep(stepNumber) {
        /** 0: "Cart" | 1: "Shipping and Payment" | 2: "Summary" **/
        const $breadcrumbs = jQuery('.cart-header__process');
        const activeClassName = 'cart-header__process-item--active';

        $breadcrumbs.find(`.${activeClassName}`).removeClass(activeClassName);
        $breadcrumbs.find('li').eq(stepNumber).addClass(activeClassName);
    },

    displayWooCommerceNotice(errorMessage) {
        const errorContainer = document.querySelector('.woocommerce-notices-wrapper');

        // Create a new notice message element
        const errorElement = document.createElement('div');
        errorElement.classList.add('woocommerce-info');
        errorElement.innerHTML = '<strong>Error:</strong> ' + errorMessage;

        // Append the notice message to the notice container
        errorContainer.appendChild(errorElement);
    },

    clearNotices() {
        const errorContainer = document.querySelector('.woocommerce-notices-wrapper');
        errorContainer.innerHTML = '';
    },

    isNewKitInTheCart() {
        // give me code for current method that will check within the table with class shop_table if there is a tr td with a data-product-name "Workspace Starter Kit" or "Healthcare Starter Kit"
        const table = document.querySelector('.shop_table');
        if (table) {
            const rows = table.querySelectorAll('tr');
            let found = false;

            rows.forEach(row => {
                const td = row.querySelector('td[data-product-name="Workspace Starter Kit"], td[data-product-name="Healthcare Starter Kit"]');
                if (td) {
                    found = true;
                }
            });

            return found;
        } else {
            return false;
        }
    },

    init() {
        let dis = this;
        this.choose_paymeny = 'null';

        // // is cart opr checkout
        if (
            document
                .querySelector("body")
                .classList.contains("woocommerce-checkout") ||
            document.querySelector("body").classList.contains("woocommerce-cart")
        ) {
            this.handleWCLabels(); // ok - labele na inputach
            this.toggleCartAdditionalItems(); // ok - toggle kitów produktów
            this.goToShippingFakeButton(); // cart - @todo
            this.handleFakeCouponInput(); // przpisuje dane z fake do prawdziwego inpta
            this.showHideVatField(); // pokazuje / ukrywa wat jak zaznaczymy company/private
            this.handleEditButton(); // todo - wraca do pierwszeg widoku checkout i zjezdza do sekcji - @todo
            this.handleOrderButton(); // fake button klika button od WC
            this.mapInputs(); // mapuje fake fieldy do fieldow wc


            this.handleCartItemParameterChange();
            /// TODO this.refreshCartInCheckout()i this.displayMessageIfQtyMoreThan(); PO this.handleCartItemParameterChange();



            this.handleVatNumberChange();
            this.handlePostcodeChange();
            this.validateVatNumber(); // validacja


            this.onboardingWithOptions(); // opcje onboardingu w cart

            // jQuery("body").on("updated_cart_totals", () => {
            //     this.displayMessageIfQtyMoreThan(); // informacja contact sales jesli przedmiotów więcej niż 50
            // });
        }

        // checkout only

        if (
            document.querySelector("body").classList.contains("woocommerce-checkout")
        ) {
            this.handleGoToSummaryButton();
            this.blockShippingCountries(); // blokuje niektóre kraje i nie pozwala wysyłać
            this.displayMessageIfQtyMoreThan(); // informacja contact sales jesli przedmiotów więcej niż 50
            this.checkoutCountrySelects(); // triggeruje country field @todo
        }


        jQuery(document).on(
            "update_checkout updated_checkout checkout_error",
            () => {
                this.whichPaymentSelected(); // sprawdzanie czy płatność zaznaczona
                this.doAfterWC();
                this.displayMessageIfQtyMoreThan();
            }
        );


        // @TODO - SPRAWDZIĆ CZY DZIAŁA  
        // this.displayMessageIfQtyMoreThan();
    },
};
