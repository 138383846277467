/* eslint-disable max-len */
import mobileNav from './mobileNav';
const navNew = {
    settings: {
        navElement: document.querySelector('.header__nav'),
        hamburgerListener: false,
    },
    init() {
        if ( ! navNew.settings.navElement ) return;
        if (window.matchMedia('(min-width: 1280px)').matches) {
            this.navigationHover();
            this.navigationScroll();
        } else {
            this.mobileAccordion();
            mobileNav.init();
            this.hamburger();
        }

        this.search();

        window.addEventListener('resize', this.handleResize);
    },
    search: () => {
        const search = document.querySelector('.header__search svg');
        const searchContainer = document.querySelector('.header__search__container');
        const closeSearch = document.querySelector('.header__search__close');

        search.addEventListener('click', () => {
            search.parentElement.classList.toggle('var_open');
        });
        document.addEventListener('click', (e) => {
            if ( ! searchContainer.contains(e.target) && ! search.contains(e.target)) {
                search.parentElement.classList.remove('var_open');
            }
        })

        closeSearch.addEventListener('click', () => {
            closeSearch.parentElement.parentElement.classList.remove('var_open');
        })

    },
    navigationScroll: () => {
        const header = document.querySelector('.header');
        if ( ! header ) {
            return;
        }
        let scrollY = window.scrollY;
        window.addEventListener('scroll', function () {
            scrollY = window.scrollY;
            if ( scrollY > 10 ) {
                header.classList.add('active')
            } else {
                header.classList.remove('active')
            }
        })
    },
    hamburger: () => {
        if (!navNew.settings.hamburgerListener) {
            const hamburger = document.querySelector('.header__hamburger');

            hamburger.addEventListener('click', () => {
                navNew.settings.navElement.style.transition = 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out';
                hamburger.classList.toggle('var_open');
                navNew.settings.navElement.classList.toggle('var_open');
                if ( mobileNav.settings.mobileNavEl ) {
                    mobileNav.settings.mobileNavEl.classList.toggle('active')
                }
            });
        }

        navNew.settings.hamburgerListener = true;
    },
    navigationHover: () => {
        const navElementsWithChildren = document.querySelectorAll('.main-children.menu-item-has-children');

        navElementsWithChildren.forEach((navElementWithChildren) => {
            navElementWithChildren.addEventListener('mouseenter', () => {
                const childrenContainer = navElementWithChildren.querySelector('.header__nav__children');

                const prevElement = document.querySelector('.header__nav__children.var_open');
                const prevChildrenElements = document.querySelectorAll('.header__nav__children.var_open ul.var_open');
                const prevHoverElement = document.querySelectorAll('li.var_hover');

                prevElement?.removeAttribute('style');
                navNew.setHeightOfOpenNavigation(childrenContainer);

                prevElement?.classList.remove('var_open');
                prevChildrenElements?.forEach((prevChildrenElement) => prevChildrenElement.classList.remove('var_open'));

                prevHoverElement?.forEach((el) => el.classList.remove('var_hover'));

                navElementWithChildren.classList.add('var_hover');
                childrenContainer.classList.add('var_open');
            });
        });

        const mainChildrens = document.querySelectorAll('.header__nav__container > .menu-item-has-children');

        mainChildrens.forEach((mainChildren) => {
            mainChildren.addEventListener('mouseenter', () => {
                const children = mainChildren.querySelector('.header__nav__subchildren');

                const prevElement = document.querySelector('.header__nav__subchildren.var_open');
                const prevHoverElement = document.querySelector('.header__nav__container > .menu-item-has-children.var_hover');

                prevElement?.classList.remove('var_open');
                prevHoverElement?.classList.remove('var_hover');

                mainChildren.classList.add('var_hover');
                children.classList.add('var_open');
            });
        });

        const subChildrens = document.querySelectorAll('.header__nav__subchildren .menu-item-has-children');

        subChildrens.forEach((subChildren) => {
            subChildren.addEventListener('mouseenter', () => {
                const children = subChildren.querySelector('.header__nav__subsubchildren');

                const prevElement = document.querySelector('.header__nav__subsubchildren.var_open');
                const prevHoverElement = document.querySelector('.header__nav__subchildren .menu-item-has-children.var_hover');

                prevElement?.classList.remove('var_open');
                prevHoverElement?.classList.remove('var_hover');

                subChildren.classList.add('var_hover');
                children.classList.add('var_open');
            });
        });

        const childrensWithoutSubmenu = document.querySelectorAll('.header__nav__container > .menu-item:not(.menu-item-has-children), .header__nav__subchildren > .menu-item:not(.menu-item-has-children)');

        childrensWithoutSubmenu.forEach((childrenWithoutSubmenu) => {
            childrenWithoutSubmenu.addEventListener('mouseenter', () => {
                const { parentElement } = childrenWithoutSubmenu;

                const hoverElements = parentElement.querySelector('.var_hover');
                const openChildren = parentElement.querySelector('.var_open');

                hoverElements?.classList.remove('var_hover');
                openChildren?.classList.remove('var_open');
            });
        });

        const navElementsWithoutChildren = document.querySelectorAll('.main-children:not(.menu-item-has-children)');

        navElementsWithoutChildren.forEach((navElementWithoutChildren) => {
            navElementWithoutChildren.addEventListener('mouseenter', () => {
                navNew.clearOpenMenu();
            });
        });

        document.querySelectorAll('.header__nav__children').forEach(navContainer => {
            navContainer.addEventListener('mouseleave', () => {
                navNew.clearOpenMenu();
            });
        });
    },
    clearOpenMenu: () => {
        document.querySelector('.header__nav__children.var_open')?.classList.remove('var_open');
        document.querySelectorAll('ul.var_open')?.forEach((el) => el.classList.remove('var_open'));
        document.querySelectorAll('li.var_hover')?.forEach((el) => el.classList.remove('var_hover'));
    },
    setHeightOfOpenNavigation: (childrenContainer) => {
        const getSubNavigations = childrenContainer.querySelectorAll('.header__nav__container, .header__nav__subchildren, .header__nav__subsubchildren');

        const heightArray = [];

        getSubNavigations.forEach((subNavigation) => {
            heightArray.push(subNavigation.offsetHeight);
        });

        const maxHeight = Math.max(...heightArray);
        const getPaddingTop = parseFloat(window.getComputedStyle(childrenContainer).paddingTop);
        const getPaddingBottom = parseFloat(window.getComputedStyle(childrenContainer).paddingBottom);

        childrenContainer.style.height = `${maxHeight + getPaddingTop + getPaddingBottom}px`;
    },
    mobileAccordion: () => {
        const openers = document.querySelectorAll('.header__arrow');

        openers.forEach((opener) => {
            opener.addEventListener('click', () => {
                const children = opener.nextElementSibling;

                children.classList.toggle('var_open');

                if (children.classList.contains('var_open')) {
                    children.style.position = 'relative';
                } else {
                    setTimeout(() => children.style.position = 'absolute', 300);
                }
            });
        });
    },
    handleResize: () => {
        navNew.hamburger();
        navNew.settings.navElement.removeAttribute('style');
    },
};

export default navNew;
