export default {
    init() {
        let tabsItems = document.querySelectorAll('.tech-specs__tabs-item');
        let activeItem = document.querySelector('.tech-specs__tabs-item--active');
        let tabsItemsContent = document.querySelectorAll('.tech-specs__content');
        let tabsItemsContentActive = document.querySelector('.tech-specs__content--active');

        const handleTabs = (item, index) => {
            activeItem.classList.remove('tech-specs__tabs-item--active');
            item.classList.add('tech-specs__tabs-item--active');
            activeItem = item;
            tabsItemsContentActive.classList.remove('tech-specs__content--active');
            tabsItemsContent[index].classList.add('tech-specs__content--active');
            tabsItemsContentActive = tabsItemsContent[index];
        }

        tabsItems.forEach((item, index) => {
            item.addEventListener('click', (e) => {
                handleTabs(e.target, index);
            });
        });
    }
}