import $ from "jquery";

export default {
    init() {
        let body = document.querySelector('body');
        let hamburger = document.querySelector('.hamburger');
        if(!hamburger) {
            return;
        }
        let siteHeader = document.querySelector('.site-header');
        let siteHeaderNav = document.querySelector('.site-header__nav');
        let siteHeaderNavActions = document.querySelector('.site-header__nav-actions');
        let menuContactSales = document.querySelector('.site-header__nav .menu-contact-sales');
        let stickyMenuBuyNow = document.querySelector('.site-header__nav .sticky-menu-cta');

        const handleHamburger = () => {
            body.classList.toggle('body--nav-active');
            hamburger.classList.toggle('hamburger--active');
            siteHeader.classList.toggle('site-header--active');
            siteHeaderNav.classList.toggle('site-header__nav--active');
        }

        hamburger && hamburger.addEventListener('click', handleHamburger);

        let menuItemHasChildren = document.querySelectorAll('.menu-item-has-children');
        let activeMenuItemHasChildren;

        const handleMenuItemDropdown = (item) => {
            if (window.innerWidth >= 1024) {
                activeMenuItemHasChildren = document.querySelector('.menu-item-has-children--active');
                if (activeMenuItemHasChildren && activeMenuItemHasChildren !== item) {
                    activeMenuItemHasChildren.classList.remove('menu-item-has-children--active');
                }
            }
            item.classList.toggle('menu-item-has-children--active');
        }

        menuItemHasChildren.forEach(item => {
            item.addEventListener('click', (e) => {
                handleMenuItemDropdown(e.target.parentElement);
            });
        })

        const handleNavScroll = (scrollPosition) => {
            if (scrollPosition > 50) {
                let isQuantityExceeded =  $('.site-header__nav .sticky-menu-cta').hasClass('no-buy');
                siteHeader && siteHeader.classList.add('site-header--nontransparent');
                siteHeaderNavActions && siteHeaderNavActions.classList.add('site-header__nav-actions--hidden');
                if (document.querySelector('.single-product') && !isQuantityExceeded) {
                    menuContactSales.classList.add('d-none');
                    stickyMenuBuyNow.classList.remove('d-none');
                }
            } else {
                siteHeader && siteHeader.classList.remove('site-header--nontransparent');
                siteHeaderNavActions && siteHeaderNavActions.classList.remove('site-header__nav-actions--hidden');
                if (document.querySelector('.single-product')) {
                    menuContactSales.classList.remove('d-none');
                    stickyMenuBuyNow.classList.add('d-none');
                }
            }
        }

        window.addEventListener('scroll', () => {
            handleNavScroll(window.scrollY);
        })
    }
}