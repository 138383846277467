import Swiper from 'swiper';
import $ from 'jquery';
export default {
    init() {
        let moreThanOneSlider = document.querySelectorAll('.swiper-slide').length > 1;

        const heroSlider = new Swiper('.hero-slider .swiper-container', {
            loop: moreThanOneSlider,
            autoplay:true,
            preventClicks:false,
            preventClicksPropagation:false,
            slideToClickedSlide:true,
            pagination: {
                el: '.hero-slider .swiper-pagination',
            },
        });

        if (moreThanOneSlider) {
            $('.swiper-pagination-bullet').click(function () {
                let i = $(this).index();
                heroSlider.slideTo(i+1);
            })
        } else {
            $('.swiper-pagination').css('display', 'none');
        }
    }
}