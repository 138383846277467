import $ from 'jquery'
const footer = {
    settings: {
        footer: null,
        footerAccordions: null
    },
    init() {
        this.initAccordion();
        window.addEventListener('resize', function() {
            footer.initAccordion();
        })
    },
    accordionListener(event) {
        if (this.classList.contains('active')) {
            $(this.nextElementSibling).slideUp();
            this.classList.remove('active')
        } else {
            $(this.nextElementSibling).slideDown();
            this.classList.add('active')
        }
    },
    initAccordion: () => {
        footer.settings.footer           = document.querySelector('footer');
        footer.settings.footerAccordions = footer.settings.footer.querySelectorAll('.footer__accordion-title')

        footer.settings.footerAccordions.forEach(function (item) {
            if ( window.matchMedia( '(min-width:768px)' ).matches === true ) {
                item.removeEventListener('click', footer.accordionListener)
            } else {
                item.addEventListener('click', footer.accordionListener)
            }
        })

    }
};

export default footer;