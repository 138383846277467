const mobileNav = {
    settings: {
        mobileNavEl: null,
    },
    init: () => {
        mobileNav.settings.mobileNavEl = document.querySelector('.nav-mobile');

        if ( ! mobileNav.settings.mobileNavEl ) return;


        const mainChildren = mobileNav.settings.mobileNavEl.querySelectorAll('.menu-item-has-children a');
        mainChildren.forEach(function ( item ) {
            item.addEventListener('click', function (e) {
                const subMenu = item.parentNode.querySelector('.nav-mobile__submenu');
                if (subMenu) {
                    e.preventDefault();
                    subMenu.classList.add('active');
                }
                if (item.parentNode.classList.contains('js-not-a-link')) {
                    e.preventDefault();
                    item.parentNode.querySelector('.nav-mobile__submenu__level').classList.toggle('active')
                    item.parentNode.querySelector('.expand-menu').classList.toggle('active');
                    item.parentNode.classList.toggle('active');
                }
            })
        })


        mobileNav.mobileMenuNavigationBack();
        mobileNav.mobileMenuNavigationClose();

    },
    mobileMenuNavigationBack: () => {
        const navMobileBack = document.querySelectorAll('.nav-mobile__back');

        if ( ! navMobileBack.length ) return;

        navMobileBack.forEach(function (item) {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                item.parentNode.parentNode.classList.remove('active')
            })
        })
    },
    mobileMenuNavigationClose: () => {
        const navMobileClose = document.querySelectorAll('.nav-mobile__close');

        if ( ! navMobileClose.length ) return;

        navMobileClose.forEach(function (item) {
            item.addEventListener('click', function (e) {
                e.preventDefault();
                item.parentNode.parentNode.classList.remove('active')
                mobileNav.resetAllMenuItems();
            })
        })
    },
    resetAllMenuItems: () => {
        const allSubMenus = mobileNav.settings.mobileNavEl.querySelectorAll('ul')
        if ( ! allSubMenus.length ) return;
        allSubMenus.forEach((submenu) => {
            submenu.classList.remove('active')
        })
        mobileNav.settings.mobileNavEl.classList.remove('active');
        document.querySelector('.header__hamburger').classList.remove('var_open')
        const jsNotALink = document.querySelectorAll('.js-not-a-link');
        if ( ! jsNotALink.length ) return;
        jsNotALink.forEach((subMenuParent) => {
            subMenuParent.classList.remove('active')
        })
    }
}

export default mobileNav;