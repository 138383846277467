import $ from 'jquery';

export default {
    init() {
        // our custom input
        const $quantityInput = $('.product-form__row #quantity');
        const $totalAmount = $('.product-banner__price');
        const $addToCartButton = $('.product-form .add_to_cart_button');
        const $addToCartStickyNav = $('.site-header__nav .sticky-menu-cta');
        const $contactSalesStickyNav = $('.site-header__nav .menu-contact-sales');
        const $wcFormCart = jQuery('form.cart');
        const $wcFormQuantityInput = $wcFormCart.find('input[type="number"]');
        const $productCustomForm = $('form.product-form');
        const $wcErrorNotice = $('.woocommerce-error');
        const $contactSalesButton = $('.product-form .contact-sales-button');
        const $productPrice = $('.product-banner__price');
        let inputMaxQuantity;

        if ($quantityInput.length === 0) {
            return;
        }

        // Select the target node.
        var priceTarget = document.querySelector('.yith_wapo_group_final_total .price')

        let priceCheckFlag = false;
        var observer = new MutationObserver(function(mutations) {
            if (!priceCheckFlag) {
                $('#quantity').trigger('change');
                updateTotalPriceTag(priceTarget.innerText);
                priceCheckFlag = true;
            }
        });

        observer.observe(priceTarget, {
            attributes:    true,
            childList:     true,
            characterData: true
        });

        $quantityInput.on('keyup input change', function () {
            if (parseInt($(this).val()) > parseInt($quantityInput.attr('data-max-quantity'))) {
                $addToCartButton.addClass('d-none');
                $contactSalesButton.removeClass('d-none');
                $productPrice.addClass('d-none');

                $addToCartStickyNav.addClass('no-buy');
                if ($(window).scrollTop() > 50) {
                    $addToCartStickyNav.addClass('d-none');
                    $contactSalesStickyNav.removeClass('d-none');
                }
            } else if ($(this).val() < parseInt($(this).attr('min'))) {
                $productPrice.addClass('d-none');
            } else {
                $addToCartButton.removeClass('d-none');
                $productPrice.removeClass('d-none');
                $contactSalesButton.addClass('d-none');
                $addToCartStickyNav.removeClass('no-buy');

                if ($(window).scrollTop() > 50) {
                    $addToCartStickyNav.removeClass('d-none');
                    $contactSalesStickyNav.addClass('d-none');
                }

                $wcFormQuantityInput.val($(this).val());
                $wcFormQuantityInput.change();
                updateTotalPriceTag($wcFormCart.find('.ywapo_tr_order_totals .amount').text());
            }
        });

        // for product options working as checkboxes
        $('.product-options input:checkbox').on('change', function () {
            let optionIndex = $(this).parent().index();
            $('.ywapo_input_container_checkbox').eq(optionIndex).find('input').trigger('click');

            // triggers change so price for multiple items is updated
            jQuery('form.cart').find('input[type="number"]').trigger('change');

            updateTotalPriceTag($wcFormCart.find('.ywapo_tr_order_totals .amount').text());
        });

        // for product options working as radio buttons
        $('.product-options input:radio').on('change', function () {
            let optionIndex = $(this).parent().index();
            $('.ywapo_input_container_radio').eq(optionIndex).find('input').trigger('click');

            // triggers change so price for multiple items is updated
            jQuery('form.cart').find('input[type="number"]').trigger('change');

            updateTotalPriceTag($wcFormCart.find('.ywapo_tr_order_totals .amount').text());
        });

        // logic for Buy now button within sticky header on product page
        $addToCartStickyNav.on('click', function(e) {
            e.preventDefault();

            //if button has 'button-loading' class, it means that ajax request in in progress
            if ($(this).hasClass('button-loading')) {
                return;
            }

            $productCustomForm.submit();
        });

        $productCustomForm.on('submit', function (e) {
            e.preventDefault();

            $addToCartButton.prop('disabled', true);
            $addToCartButton.addClass('button-loading');
            $wcErrorNotice.addClass('d-none');

            disableBuyNowButtonStickyNav();

            let url = $productCustomForm.attr('action');
            let quantity = $quantityInput.val();
            let add = document.querySelector('[name="add-to-cart"]').value;
            let optionsObject = {};
            let optionsArray = [];
            let $checkboxOptions = $('.ywapo_input_container_checkbox input:checked');
            let $radioOptions = $('.ywapo_input_container_radio input:checked');
            let $compulsoryOptions;
            let yith_custom_attributes_name = $('.woocommerce_template_single_add_to_cart .ywapo_group_container').attr('id');
            let yith_attribute_number;

            if (yith_custom_attributes_name) {
                yith_attribute_number = yith_custom_attributes_name.split('ywapo_value_');
            }

            let ywapo_ajax_key;

            if (quantity === '') {
                showErrorNotice("Product quantity can't be empty.");
                hideLoaders();
            }

            if ($checkboxOptions.length > 0) {
                ywapo_ajax_key = 'ywapo_checkbox_' + + yith_attribute_number[1];

                $checkboxOptions.each(function (index, element) {
                    let elemIndex = $(element).parent().index();
                    optionsObject[elemIndex] = 'ywapo_value_' + yith_attribute_number[1];
                });

                getCompulsoryAddons(add).then(result => {
                    if (result === 0) {
                        showErrorNotice();
                        hideLoaders();

                        return;
                    }

                    $compulsoryOptions = result.addonsObject;

                    $compulsoryOptions.forEach(function (item) {
                        optionsObject[item] = 'ywapo_value_' + yith_attribute_number[1];
                    });

                    let data = {
                        'quantity': quantity,
                        'add-to-cart': add,
                        'yith_wapo_is_single': '1'
                    };

                    data[ywapo_ajax_key] = optionsObject;

                    ajaxAddToCart(url, data);

                });
            } else if ($radioOptions.length > 0) {
                ywapo_ajax_key = 'ywapo_radio_' + + yith_attribute_number[1];

                let elemIndex = $radioOptions.parent().index();
                optionsArray.push(elemIndex);

                let data = {
                    'quantity': quantity,
                    'add-to-cart': add,
                    'yith_wapo_is_single': '1'
                };

                data[ywapo_ajax_key] = optionsArray;

                ajaxAddToCart(url, data);
            } else {
                let data = {
                    'quantity': quantity,
                    'add-to-cart': add,
                };

                ajaxAddToCart(url, data);
            }

        });

        function updateTotalPriceTag(price) {
            $totalAmount.html(price);
        }

        function ajaxAddToCart(url, data) {
            $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: data,
                cache: false,
            }).always(function (dataOrjqXHR) {
                let isAddedToCart = $(dataOrjqXHR.responseText).find('.added-to-cart-true').length;
                let limitedQuantity = $(dataOrjqXHR.responseText).find('.button__limit-quantity').length;

                if (dataOrjqXHR.status !== 200 || ( isAddedToCart === 0 && limitedQuantity === 0 )) {
                    const responseWcNoticeClone = $(dataOrjqXHR.responseText).find('.wc-notice').clone().html();
                    const preparedWcNotice = $(responseWcNoticeClone).removeAttr('class').removeAttr('role').wrap('<ul></ul>');

                    showErrorNotice(preparedWcNotice);
                } else {
                    ajaxGetCartQuantity();
                }

                hideLoaders();
                if ($productCustomForm.hasClass('product-form--hide-quantity')) {
                    window.location.replace(app_js_localized.checkout_url);
                }

            });
        }

        function ajaxGetCartQuantity() {
            $.ajax({
                url: ajaxUrl,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    'action': 'get_cart_items_quantity_ajax',
                },
                cache: false,
            }).done(function (response) {
                $('.nav-cart__value').html(response.itemsQuantity)
                $('.wc-notice').html(response.added_to_cart);
            });
        }

        /*
           Get compulsory options by server call - making sure that we got correct compulsory options, no matter if user
           tried to change site HTML code to hijack subscription checkboxes
         */
        function getCompulsoryAddons(id) {
            return $.ajax({
                url: ajaxUrl,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: {
                    'action': 'get_compulsory_product_addons',
                    'productId': id,
                },
                cache: false,
            });
        }

        function showErrorNotice(errorNotice = false) {
            let errorNoticeMsg;
            if (errorNotice) {
                errorNoticeMsg = errorNotice;
            } else {
                errorNoticeMsg = 'Sorry - something went wrong. Please try again later.';
            }
            $wcErrorNotice
                .html(errorNoticeMsg)
                .removeClass('d-none');
        }

        function disableBuyNowButtonStickyNav() {
            $addToCartStickyNav.addClass('button-loading');
            $addToCartStickyNav.prop('disabled', true);
        }

        function hideLoaders() {
            $addToCartButton.prop('disabled', false);
            $addToCartButton.removeClass('button-loading');
            $addToCartStickyNav.removeClass('button-loading');
            $addToCartStickyNav.prop('disabled', false);
        }

    }
}
