import './../css/_app.scss'
// import pageTransitions from './animations/transitions';
// import * as basicScroll from 'basicscroll'
import utils from './utils';
import navNew from './navNew';
import accordion from './accordion';
import productTabs from './productTabs';
import homeSlider from './homeSlider';
import customNav from './customNav';
import productPage from './productPage';
import cart from './cart';
import payment from './payment';
import checkoutOrderPay from "./checkoutOrderPay";
import footer from './footer';

export default class App {
	constructor() {
		this.initDev(); // tests and shit
		this.initBody(); // runs on initialize & after barba
	}

	initBody() {

	}

	initCore() {
		// pageTransitions(); // barbaajs
		navNew.init();
		accordion.init();
		productTabs.init();
		homeSlider.init();
		customNav.init();
		cart.init();
		payment.init();
		footer.init();
		productPage.init();
		checkoutOrderPay.init();
	}

	initDev() {
		utils.clone('[data-clone]');
	}
}


function LoadApp() {
	const app = new App();
	app.initCore(); // runs on initialize
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', LoadApp);
} else {
	LoadApp();
}