export default {
    init() {
        if (document.querySelector('.wc_payment_method')) {
            let wcPaymentMethods = document.querySelectorAll('.wc_payment_method');
            let wcPaymentMethodsParent = document.querySelector('ul.wc_payment_methods');
            let wcPaymentBoxes = document.querySelectorAll('.payment_box');
            let wcPaymentContainerWidth = wcPaymentMethodsParent.offsetWidth;
            let styleGetter = document.querySelector('.payment_box.payment_method_braintree_credit_card');
            if (styleGetter) {
                styleGetter.style.width = `${wcPaymentContainerWidth}px`;

                window.addEventListener('resize', () => {
                    wcPaymentContainerWidth = document.querySelector('.wc_payment_methods').offsetWidth;
                    styleGetter.style.width = `${wcPaymentContainerWidth}px`;
                })
            }


            const clearWcPaymentMethodErrors = () => {
                let wcErrorMessages = document.querySelector('.wc_error_messages');
                if (wcErrorMessages) {
                    wcErrorMessages.innerHTML = '';
                }
            }

            const removeActiveChoice = () => {
                wcPaymentMethods = document.querySelectorAll('.wc_payment_method');
                wcPaymentMethods.forEach(payment => {
                    payment.classList.remove('wc_payment_method--active');
                    payment.querySelector('input').checked = false;
                });
                wcPaymentBoxes = document.querySelectorAll('.payment_box');
                wcPaymentBoxes.forEach(box => {
                    box.classList.remove('payment_box--active');
                })
                // clearWcPaymentMethodErrors();
            }

            // const addClassesToPaymentElements = () => {

            // }


            const calculateWidth = (element, newWidth) => {
                if(element) {
                    element.style.width = `${newWidth}px`;
                }
            }
            document.addEventListener('click', (e) => {

                // addClassesToPaymentElements();
                let checkoutPayment = document.querySelector('.woocommerce-checkout-payment');
                let orderPayment = document.querySelector('.wc_payment_methods');
                let paymentMethod = document.querySelector('.wc_payment_method');
                wcPaymentContainerWidth = document.querySelector('.wc_payment_methods').offsetWidth;
                let creditCart = document.querySelector('.payment_box.payment_method_braintree_credit_card');
                let klarnaOverTime = document.querySelector('.payment_box.payment_method_klarna_payments_pay_over_time');
                let klarnaPayLater = document.querySelector('.payment_box.payment_method_klarna_payments_pay_later');
                calculateWidth(creditCart, wcPaymentContainerWidth);
                calculateWidth(klarnaOverTime, wcPaymentContainerWidth);
                calculateWidth(klarnaPayLater, wcPaymentContainerWidth);

                if(e.target.classList.contains('payment_method_klarna_payments_pay_over_time') || e.target.classList.contains('payment_method_klarna_payments_pay_later')) {
                    checkoutPayment.setAttribute('style', 'padding-bottom: 210px !important');
                }
                if (e.target.classList.contains('wc_payment_method')) {
                    removeActiveChoice();
                    e.target.classList.add('wc_payment_method--active');
                    e.target.querySelector('input').checked = true;

                    for (var i = 0; i < e.target.childNodes.length; i++) {
                        if (e.target.childNodes[i].classList) {
                            if (e.target.childNodes[i].classList.contains('payment_box')) {
                                e.target.childNodes[i].classList.add('payment_box--active');
                            }
                        }
                    }
                    let offsetH = 0;
                    if (e.target.querySelector('.payment_box')) {
                        offsetH = e.target.querySelector('.payment_box').offsetHeight;
                    }

                    if (checkoutPayment) {
                        checkoutPayment.setAttribute('style', `padding-bottom: ${offsetH}px!important`);
                    } else if (orderPayment) {
                        orderPayment.setAttribute('style', `padding-bottom: ${offsetH+50}px!important`);
                    }
                } else if (e.target.tagName.toLowerCase() === 'label') {
                    removeActiveChoice();
                    e.target.parentElement.classList.add('wc_payment_method--active');
                    for (var i = 0; i < e.target.parentElement.childNodes.length; i++) {
                        if (e.target.parentElement.childNodes[i].classList) {
                            if (e.target.parentElement.childNodes[i].classList.contains('payment_box')) {
                                e.target.parentElement.childNodes[i].classList.add('payment_box--active');
                            }
                        }
                    }
                    let offsetH2 = 0;
                    if (e.target.parentElement.querySelector('.payment_box')) {
                        offsetH2 = e.target.parentElement.querySelector('.payment_box').offsetHeight
                    }
                    if (checkoutPayment) {
                        checkoutPayment.setAttribute('style', `padding-bottom: ${offsetH2}px!important`);
                    } else if (orderPayment) {
                        orderPayment.setAttribute('style', `padding-bottom: ${offsetH2+50}px!important`);
                    }
                }
            });
        }
    }
}