export default {
    init() {
        if (document.querySelector('.specs-accordion')) {
            let accordionIcons = document.querySelectorAll('.specs-accordion__item-icon');
            
            const handleAccordionItem = (element) => {
                element.classList.toggle('specs-accordion__item--active');
            }
            
            accordionIcons.forEach(icon => {
                icon.addEventListener('click', (e) => {
                    handleAccordionItem(e.target.parentElement.parentElement);
                });
            });
        }
    }
}