import $ from "jquery";

export default {
    payForOrderClickHandle() {
        const checkConsents = () => {
           let isTermsConsentChecked = $('#term_1').is(':checked');
           let isPolicyConsentChecked = $('#term_2').is(':checked');
           let $woocommerceError = $('.woocommerce-error');

           if (!isTermsConsentChecked) {
               $woocommerceError.append('<li>Please agree to our <strong>TERMS OF SERVICE</strong>.</li>');
           }

           if (!isPolicyConsentChecked) {
               $woocommerceError.append('<li>Please agree to our <strong>PRIVACY POLICY</strong>.</li>');
           }

            if (!isTermsConsentChecked || !isPolicyConsentChecked) {
                $woocommerceError.show();
            }

            return isTermsConsentChecked && isPolicyConsentChecked;
        };

        const isPaymentMethodSelected = () => {
            let paymentMethodsContainer = $('.wc_payment_methods');
            let $woocommerceError = $('.woocommerce-error');

            let paymentMethodStatus = paymentMethodsContainer.find('input:checked').length !== 0;

            if (!paymentMethodStatus) {
                $woocommerceError.show();
                $woocommerceError.append('<li>Please select <strong>PAYMENT METHOD</strong>.</li>');
            }

            return paymentMethodStatus;
        };

        const clearErrorBox = () => {
            let $woocommerceError = jQuery('.woocommerce-error');

            if ($woocommerceError.is(':visible')) {
                $woocommerceError.hide();
                $woocommerceError.html('');
            }
        }

        const createWcErrorWrapperIfNotExists = () => {
            let $noticesWrapper = $('.woocommerce-notices-wrapper');

            if ($('.woocommerce-error').length === 0) {
                $noticesWrapper.append('<ul class="woocommerce-error" role="alert"></ul>');
            }
        };

        $('#place_order').on('click', function(e) {
            let consentsStatus;
            let paymentMethodStatus;

            createWcErrorWrapperIfNotExists();
            clearErrorBox();
            consentsStatus = checkConsents();
            paymentMethodStatus = isPaymentMethodSelected();

            if (!consentsStatus || !paymentMethodStatus) {
                e.preventDefault();
            }
        });
    },


    init() {
        if (
            document.querySelector("body").classList.contains("woocommerce-order-pay")
        ) {
            this.payForOrderClickHandle();
        }
    }
}